.modal {
  position: fixed;
  z-index:50;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  //padding-left: 100px;

  .ModalCrop {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cropper{
      position: relative;
      width: 400px;
      height: 400px;
      background-color: rgba(0,0,0,0.5);
      img{
        margin: auto;
        width: auto;
        height: auto;
        border-radius: 0;
      }
    }
  }

  .inputUploadImg{
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    font-style: italic;
    text-decoration: underline;
  }

  .modal-content{
    display:flex;
    flex-direction: column;
    overflow: auto;
    max-height: 95vh;
    z-index:50;
    background-color: #ebecf1;

    border: #A36298 4px solid;
    border-radius:10px;

    padding-top:10px;
    padding-bottom:10px;
  }
  h5{
    font-size: 1.5em;
    font-weight: 700;
  }
  .modal-header{
  //  align-items: center;
    //color:white;
  }
  .modal-dialog{
    width: 80%;
    max-width: 80%;
    margin: auto;
    margin-top: 50vh;
    transform: translateY(-50%);
  }
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.messageBox {
  text-align: center;
}

.btnBox {
  z-index:50;
  display: flex;
  justify-content: space-evenly;
}

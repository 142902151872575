
.footer{
/*  position: fixed;*/
  bottom:0;
  background-color: white;
  justify-content: end;
}

h4 {
  text-align: center;
}

#scrollPartner {
  display:flex;
  flex-direction: column;
}
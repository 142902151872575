@import "../utilities/colors";

#header_menu {
  position: sticky;
  top: 0;
  z-index: 10;
  width:100%;
  margin: unset;
  padding: unset;
}



#side_menu {
  height:100%;
}

#sandwichMenu{
  color:$second-color;
}

.spaced-menu{
  display: flex;
  justify-content: space-around;
}

#main_nav {
  vertical-align: center;
}

#bannerbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: none;
  box-shadow: none;
  flex-wrap: wrap;
}

#logo {
  max-width: 250px;
  padding:unset;
  img {
    width: 100%;
    height:auto;
  }
}

.banner {
  max-width: 700px;
  border:none;
  padding:unset;
  img {
    width: 100%;
    height:auto;
  }
}

.wrapped{
  flex-wrap: wrap;
}
